type StringIteratee<EntryType> = string | ((arg0: EntryType) => string);
type GenericIteratee<EntryType, CompareType> = string | ((arg0: EntryType) => CompareType);

type SortOrder = 'asc' | 'desc';
type CompareFunction<CompareType> = (a: CompareType, b: CompareType) => number;

/**
 * Examples:
 *
 * const testInput = [{id: 'n', name: 'neuza'}, {id: 'g', name: 'gabriela'}];
 *
 * CALL: mapBy(testInput, 'id')
 * RESULT: {
 *   n: {id: 'n', name: 'neuza'},
 *   g: {id: 'g', name: 'gabriela'},
 * }
 *
 * CALL: mapBy(testInput, ({name}) => name.length)
 * RESULT: {
 *   '5': {id: 'n', name: 'neuza'},
 *   '8': {id: 'g', name: 'gabriela'},
 * }
 *
 */
export function mapBy<EntryType>(
  array: Array<EntryType>,
  iteratee: StringIteratee<EntryType>,
): Partial<Record<string, EntryType>> {
  const result: Partial<Record<string, EntryType>> = {};
  for (const value of array) {
    const key =
      typeof iteratee === 'function' ? iteratee(value) : ((value as any)[iteratee] as string);
    if (key in result) {
      throw new Error(
        `Found duplicate key ${key} for iteratee ${iteratee} on array ${JSON.stringify(array)}`,
      );
    }
    result[key] = value;
  }
  return result;
}

/**
 * Examples:
 *
 * const testInput = [{gender: 'f', name: 'neuza'}, {gender: 'f', name: 'gabriela'}, {gender: 'm', name: 'francisco'}, {gender: 'm', name: 'jose'}];
 *
 * CALL: groupBy(testInput, 'gender')
 * RESULT: {
 *   f: [{gender: 'f', name: 'neuza'}, {gender: 'f', name: 'gabriela'}],
 *   m: [{gender: 'm', name: 'francisco'}, {gender: 'm', name: 'jose'}],
 * }
 *
 * CALL: groupBy(testInput, ({name}) => name.length <= 5 ? 'shortName' : 'longName')
 * RESULT: {
 *   shortName: [{gender: 'f', name: 'neuza'}, {gender: 'm', name: 'jose'}],
 *   longName: [{gender: 'f', name: 'gabriela'}, {gender: 'm', name: 'francisco'}],
 * }
 *
 */
export function groupBy<EntryType>(
  array: Array<EntryType>,
  iteratee: StringIteratee<EntryType>,
): Record<string, Array<EntryType>> {
  const result: Record<string, Array<EntryType>> = {};
  for (const value of array) {
    const key =
      typeof iteratee === 'function' ? iteratee(value) : ((value as any)[iteratee] as string);
    if (!(key in result)) {
      result[key] = [];
    }
    result[key].push(value);
  }
  return result;
}

function getComparisonScore<EntryType, CompareType>(
  a: EntryType,
  b: EntryType,
  iteratee: GenericIteratee<EntryType, CompareType>,
  compareFuncOrOrder?: SortOrder | CompareFunction<CompareType>,
): number {
  const aValue =
    typeof iteratee === 'function' ? iteratee(a) : ((a as any)[iteratee] as CompareType);
  const bValue =
    typeof iteratee === 'function' ? iteratee(b) : ((b as any)[iteratee] as CompareType);
  if (typeof compareFuncOrOrder === 'function') {
    return compareFuncOrOrder(aValue, bValue);
  }

  const sortDirectionMul = compareFuncOrOrder === 'desc' ? -1 : 1;
  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return sortDirectionMul * aValue.localeCompare(bValue);
  } else if (typeof aValue === 'number' && typeof bValue === 'number') {
    return sortDirectionMul * (aValue - bValue);
  } else if (aValue instanceof Date && bValue instanceof Date) {
    return sortDirectionMul * (aValue.getTime() - bValue.getTime());
  } else {
    throw new Error(
      'compareFuncOrOrder was not provided as a function but values to getComparisonScore are not string or numbers',
    );
  }
}

export function sortBy<EntryType, CompareType>(
  array: Array<EntryType>,
  iteratee: GenericIteratee<EntryType, CompareType>,
  compareFuncOrOrder?: SortOrder | CompareFunction<CompareType>,
): Array<EntryType>;

export function sortBy<EntryType, CompareType>(
  array: Array<EntryType>,
  sortOptionsArray: Array<
    | GenericIteratee<EntryType, unknown>
    | readonly [GenericIteratee<EntryType, unknown>, SortOrder | CompareFunction<unknown>]
  >,
): Array<EntryType>;

/**
 * Examples:
 *
 * const testInput = [{name: 'neuza', age: 10}, {name: 'gabriela', age: 25}, {name: 'francisco', age: 99}, {name: 'jose', age: 25}];
 *
 * CALL: sortBy(testInput, 'age')
 * RESULT: [
 *   { name: 'neuza', age: 10 },
 *   { name: 'gabriela', age: 25 },
 *   { name: 'jose', age: 25 },
 *   { name: 'francisco', age: 99 }
 * ]
 *
 * CALL: sortBy(testInput, 'age', 'desc')
 * RESULT: [
 *   { name: 'francisco', age: 99 },
 *   { name: 'gabriela', age: 25 },
 *   { name: 'jose', age: 25 },
 *   { name: 'neuza', age: 10 }
 * ]
 *
 * CALL: sortBy(testInput, 'name')
 * RESULT: [
 *   { name: 'francisco', age: 99 },
 *   { name: 'gabriela', age: 25 },
 *   { name: 'jose', age: 25 },
 *   { name: 'neuza', age: 10 }
 * ]
 *
 * CALL: sortBy(testInput, ({name}) => name.length)
 * RESULT: [
 *   { name: 'jose', age: 25 },
 *   { name: 'neuza', age: 10 },
 *   { name: 'gabriela', age: 25 },
 *   { name: 'francisco', age: 99 }
 * ]
 *
 * CALL: sortBy(testInput, 'name', (name1, name2) => name1.length > name2.length ? 1 : -1)
 * RESULT: [
 *   { name: 'jose', age: 25 },
 *   { name: 'neuza', age: 10 },
 *   { name: 'gabriela', age: 25 },
 *   { name: 'francisco', age: 99 }
 * ]
 *
 * CALL: sortBy(testInput, ['age', ['name', 'desc']])
 * RESULT: [
 *   { name: 'neuza', age: 10 },
 *   { name: 'jose', age: 25 },
 *   { name: 'gabriela', age: 25 },
 *   { name: 'francisco', age: 99 }
 * ]
 *
 * CALL: sortBy(testInput, [['age', 'desc'], ({name}) => name.length])
 * RESULT: [
 *   { name: 'francisco', age: 99 },
 *   { name: 'jose', age: 25 },
 *   { name: 'gabriela', age: 25 },
 *   { name: 'neuza', age: 10 }
 * ]
 *
 */
export function sortBy<EntryType, CompareType>(
  array: Array<EntryType>,
  iteratee:
    | GenericIteratee<EntryType, CompareType>
    | Array<
        | GenericIteratee<EntryType, unknown>
        | readonly [GenericIteratee<EntryType, unknown>, SortOrder | CompareFunction<unknown>]
      >,
  compareFuncOrOrder?: SortOrder | CompareFunction<CompareType>,
): Array<EntryType> {
  const sortOptionsArray = Array.isArray(iteratee)
    ? iteratee
    : [[iteratee, compareFuncOrOrder] as const];
  return [...array].sort((a, b) => {
    for (const sortOptions of sortOptionsArray) {
      const [iteratee, compareFuncOrOrder] = Array.isArray(sortOptions)
        ? sortOptions
        : [sortOptions];
      const comparisonScore = getComparisonScore(a, b, iteratee, compareFuncOrOrder);
      if (comparisonScore !== 0) {
        return comparisonScore;
      }
    }
    return 0;
  });
}
/**
 * Examples:
 *
 * CALL: flatten([[1, 2, 3], 4, [5, 6], 7, 8, [], [9, 10]])
 * RESULT:[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
 *
 */
export function flatten<A>(arrayOfArrays: Array<A>): FlatArray<A, 0>[] {
  return arrayOfArrays.reduce((accumulator, array) => {
    // @ts-ignore
    return accumulator.concat(Array.isArray(array) ? array : [array]);
  }, []);
}
/**
 * Examples:
 *
 * const testInput = [{name: 'aaa', type: 'x'}, {name: 'aaa', type: 'y'}, {name: 'bbb', type: 'x'}, {name: 'bbb', type: 'y'}, {name: 'bbb', type: 'z'}];
 *
 * CALL: uniqBy(testInput, 'name')
 * RESULT: [
 *   { name: 'aaa', type: 'x' },
 *   { name: 'bbb', type: 'x' }
 * ]
 *
 * CALL: uniqBy(testInput, 'type')
 * RESULT: [
 *   { name: 'aaa', type: 'x' },
 *   { name: 'aaa', type: 'y' },
 *   { name: 'bbb', type: 'z' }
 * ]
 *
 * CALL: uniqBy(testInput, ({name}) => name.length)
 * RESULT: [
 *   { name: 'aaa', type: 'x' }
 * ]
 *
 */
export function uniqBy<EntryType, CompareType>(
  array: Array<EntryType>,
  iteratee: GenericIteratee<EntryType, CompareType>,
): Array<EntryType> {
  const set = new Set();
  const uniqArray: Array<EntryType> = [];
  for (const value of array) {
    const key =
      typeof iteratee === 'function' ? iteratee(value) : ((value as any)[iteratee] as CompareType);
    if (!set.has(key)) {
      set.add(key);
      uniqArray.push(value);
    }
  }
  return uniqArray;
}

/**
 * Examples:
 *
 * CALL: uniq([2, 7, 3, 8, 4, 7, 2, 8, 2, 5, 8, 3, 1])
 * RESULT: [2, 7, 3, 8, 4, 5, 1]
 *
 * CALL: uniq(['a', 'c', 'b', 'b', 'a', 'c'])
 * RESULT: [ 'a', 'c', 'b' ]
 *
 */
export function uniq<EntryType>(array: Array<EntryType>): Array<EntryType> {
  return [...new Set(array)];
}

/**
 * Examples:
 *
 * CALL: range(8)
 * RESULT: [0, 1, 2, 3, 4, 5, 6, 7]
 *
 * CALL: range(8, 14)
 * RESULT: [8, 9, 10, 11, 12, 13]
 *
 */
export function range(start: number, end?: number): Array<number> {
  if (end === undefined) {
    end = start;
    start = 0;
  }
  const result: Array<number> = [];
  if (end < start) {
    for (let n = start; n > end; n--) {
      result.push(n);
    }
  } else {
    for (let n = start; n < end; n++) {
      result.push(n);
    }
  }
  return result;
}

/**
 * Examples:
 *
 * CALL: removeItem(['a', 'b', 'c', 'd'], 'c')
 * RESULT: ['a', 'b', 'd']
 *
 */
export function removeItem<T>(arr: Array<T>, value: T): Array<T> {
  const index = arr.indexOf(value);
  const arrCopy = [...arr];
  if (index > -1) {
    arrCopy.splice(index, 1);
  }
  return arrCopy;
}

/**
 * Examples:
 *
 * CALL: shuffle([1, 2, 3, 4])
 * RESULT: [2, 3, 4, 1] or other random order
 *
 */
export function shuffle<T>(array: Array<T>): Array<T> {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

/**
 * Examples:
 *
 * CALL: arrayEquals([1,2,3], [1,2,3])
 * RESULT: true
 *
 * CALL: arrayEquals([1,2,3], [1,2,3, 4])
 * RESULT: false
 *
 * CALL: arrayEquals([1,2,3], [1,2,4])
 * RESULT: false
 *
 * CALL: arrayEquals(['a','b','c'], ['a','b','c'])
 * RESULT: true
 *
 * CALL: arrayEquals(['a','b','c'], ['a','b','d'])
 * RESULT: false
 *
 * CALL: arrayEquals([{a: 3}], [{a: 3}])
 * RESULT: false
 *
 */
export function arrayEquals(arr1: Array<unknown>, arr2: Array<unknown>): boolean {
  return arr1.length === arr2.length && arr1.every((val, index) => val === arr2[index]);
}

/**
 * Examples:
 *
 * CALL: zip([1, 2, 3], ['a', 'b', 'c'])
 * RESULT: [[1, 'a'], [2, 'b'], [3, 'c']]
 *
 */
export function zip<T1, T2>(arr1: Array<T1>, arr2: Array<T2>): Array<[T1, T2]> {
  if (arr1.length !== arr2.length) {
    throw new Error(
      `Array a length (${arr1.length}) different from array b length (${arr2.length})`,
    );
  }
  return arr1.map((el, i) => [el, arr2[i]]);
}

/**
 * Examples:
 *
 * CALL: unzip([[1, 'a'], [2, 'b'], [3, 'c']])
 * RESULT: [[1, 2, 3], ['a', 'b', 'c']]
 *
 */
export function unzip<T1, T2>(arr: Array<[T1, T2]>): [Array<T1>, Array<T2>] {
  const arr1: Array<T1> = [];
  const arr2: Array<T2> = [];
  for (const [el1, el2] of arr) {
    arr1.push(el1);
    arr2.push(el2);
  }
  return [arr1, arr2];
}

/**
 * Examples:
 *
 * CALL: sum([])
 * RESULT: 0
 *
 * CALL: sum([1, 2, 3])
 * RESULT: 6
 *
 */
export function sum(numbers: Array<number>): number {
  let total = 0;
  for (const number of numbers) {
    total += number;
  }
  return total;
}

/**
 * Capitalizes the first letter of a string.
 *
 * Examples:
 *
 * CALL: capitalize('hello world')
 * RESULT: 'Hello world'
 */
export function capitalize(str: string): string {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Picks the specified properties from an object and returns a new object with those properties.
 *
 * Examples:
 *
 * CALL: pick({name: 'John', age: 30, city: 'San Francisco'}, ['name', 'city'])
 * RESULT: {name: 'John', city: 'San Francisco'}
 */
export function pick<T extends object, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  const newObj = {} as Pick<T, K>;
  for (const key of keys) {
    if (key in obj) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

/**
 * Omits the specified properties from an object and returns a new object without those properties.
 *
 * Examples:
 *
 * CALL: omit({name: 'John', age: 30, city: 'San Francisco'}, ['age', 'city'])
 * RESULT: {name: 'John'}
 */
export function omit<T extends object, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
  const newObj = { ...obj };
  for (const key of keys) {
    delete newObj[key];
  }
  return newObj;
}

/**
 * Takes two arrays and returns a new array with elements that are present in the first but not the second.
 *
 * Examples:
 *
 * CALL: difference([2, 1], [2, 3])
 * RESULT: [1]
 */
export function difference<T>(array1: Array<T>, array2: Array<T>): Array<T> {
  return array1.filter(value => !array2.includes(value));
}

/**
 * Takes two arrays and returns a new array with elements that are only present in one of them.
 *
 * Examples:
 *
 * CALL: symmetricDifference([2, 1], [2, 3])
 * RESULT: [1, 3]
 */
export function symmetricDifference<T>(array1: Array<T>, array2: Array<T>): Array<T> {
  return array1
    .filter(value => !array2.includes(value))
    .concat(array2.filter(value => !array1.includes(value)));
}

/**
 * Takes two arrays and returns a new array with elements that are present in both.
 *
 * Examples:
 *
 * CALL: intersection([2, 1], [2, 3])
 * RESULT: [2]
 */
export function intersection<T>(arr1: Array<T>, arr2: Array<T>): Array<T> {
  return arr1.filter(value => arr2.includes(value));
}

/**
 * Takes two arrays and returns a new array with elements that are present in either one or both.
 *
 * Examples:
 *
 * CALL: union([2, 1], [2, 3])
 * RESULT: [2, 1, 3]
 */
export function union<T>(arr1: Array<T>, arr2: Array<T>): Array<T> {
  return Array.from(new Set([...arr1, ...arr2]));
}
